<template>
  <div>
    <v-row align="center">
      <v-col cols="auto" class="pr-12">
        <h3>Contract Details</h3>
      </v-col>
      <v-col></v-col>
    </v-row>
    <v-divider></v-divider>
    <v-data-table
      :headers="tableHeaders"
      :items="teamMember.postings"
      no-data-text="This team member doesn't have any postings assigned to them"
    >
      <template v-slot:item.salary="{ item }">
        <locked-value
          :id="item.id"
          table="postings"
          field="salary"
          prefix="£"
        />
      </template>
    </v-data-table>
  </div>
</template>

<script>
export default {
  props: {
    teamMember: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      tableHeaders: [
        { text: "Post Title", value: "post.name" },
        { text: "Start Date", value: "formatted_dates.start_date" },
        { text: "Capacity", value: "capacity" },
        { text: "Productivity", value: "product" },
        { text: "Salary", value: "salary" },
      ],
    };
  },
};
</script>
